import React from 'react';

import kehpaopas from '../assets/files/Digis-ELY-kehittamispalvelut-Opas.pdf';
import Hero from '../components/Hero';
import PageStyles from '../styles/PageStyles';

export default function ElyOpasPage() {
  return (
    <PageStyles>
      <Hero>
        <span>LATAA</span>
        <br /> ELY-OPAS!
      </Hero>
      <a className="link" href={kehpaopas}>
        Lataa opas
      </a>
    </PageStyles>
  );
}
